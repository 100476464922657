import React, { ReactNode } from 'react';
import {
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Tag,
  Spacer,
  Flex,
  type FormControlProps,
} from '@chakra-ui/react';
import { FieldError, Merge } from 'react-hook-form';

type FormItemProps = FormControlProps & {
  dataName?: string;
  icon?: JSX.Element;
  label?: string | JSX.Element;
  leftElm?: JSX.Element | null;
  rightElm?: JSX.Element | null;
  isRequired?: boolean;
  isSubItem?: boolean;
  error?:
    | FieldError
    | Merge<FieldError, (FieldError | undefined)[]>
    | undefined;
  info?: ReactNode;
  id?: string;
};

export function FormItem({
  // dataName,
  icon,
  label,
  leftElm,
  rightElm,
  isRequired,
  isSubItem,
  error,
  children,
  info,
  id,
  ...rest
}: FormItemProps) {
  return (
    <FormControl isInvalid={error ? true : false} {...rest}>
      {id && <a id={id} />}
      <Flex gap={2} align="center" pb={2}>
        {icon}
        <FormLabel
          display="flex"
          alignItems="center"
          gap={2}
          color={isSubItem ? 'gray.300' : 'gray.500'}
        >
          {label}
        </FormLabel>
        {isRequired && (
          <Tag variant="outline" colorScheme="orange" size="sm">
            必須
          </Tag>
        )}
        {leftElm}
        <Spacer />
        {rightElm}
      </Flex>
      {children}
      <FormHelperText>{info}</FormHelperText>
      <FormErrorMessage size="md">{error?.message}</FormErrorMessage>
    </FormControl>
  );
}
